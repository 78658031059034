// @ts-ignore -- Unused import
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
// @ts-ignore -- Unused import
import { jsonLightReadStringPropertyValue } from '@msdyn365-commerce/retail-proxy/dist/externals';

/**
 * PromiseDateEntity entity interface.
 */
export interface IPromiseDateEntity {
    itemId: string;
    availDate?: string;
}

/**
 * UserLevelEntity entity interface.
 */
export interface IUserLevelEntity {
    UserId: string;
    UserAccessLevel?: number;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * CalculatePromiseDateResponse entity interface.
 */
export interface ICalculatePromiseDateResponse {
    jsonString?: string;
    promiseDates?: IPromiseDateEntity[];
}

/**
 * GetPromiseStockAvailQtyResponse entity interface.
 */
export interface IGetPromiseStockAvailQtyResponse {
    QtyAvail: number;
}

/**
 * CalculateStockAvailDateResponse entity interface.
 */
export interface ICalculateStockAvailDateResponse {
    DateAvail: Date;
    Success: boolean;
}

/**
 * GetUserLevelByExternalIdResponse entity interface.
 */
export interface IGetUserLevelByExternalIdResponse {
    userLevel: number;
    userId?: string;
}

/**
 * PromiseDateEntity entity class.
 */
export class PromiseDateEntityExtensionClass implements IPromiseDateEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public itemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public availDate: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.itemId = odataObject.itemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.availDate = odataObject.availDate;
    }
}

/**
 * UserLevelEntity entity class.
 */
export class UserLevelEntityExtensionClass implements IUserLevelEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public UserId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public UserAccessLevel: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.UserId = odataObject.UserId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.UserAccessLevel = odataObject.UserAccessLevel;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        // @ts-ignore
                        if (EntityClasses[className]) {
                            // @ts-ignore
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * CalculatePromiseDateResponse entity class.
 */
export class CalculatePromiseDateResponseExtensionClass implements ICalculatePromiseDateResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public jsonString: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public promiseDates: IPromiseDateEntity[];

    // Navigation properties names

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public static promiseDatesPropertyName: string = 'promiseDates';

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.jsonString = odataObject.jsonString;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.promiseDates = undefined;
        if (odataObject.promiseDates) {
            this.promiseDates = [];
            for (var i = 0; i < odataObject.promiseDates.length; i++) {
                if (odataObject.promiseDates[i]) {
                    if (odataObject.promiseDates[i]['@odata.type']) {
                        var className: string = odataObject.promiseDates[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        // @ts-ignore
                        if (EntityClasses[className]) {
                            // @ts-ignore
                            this.promiseDates[i] = new EntityClasses[className](odataObject.promiseDates[i]);
                        }
                    } else {
                        this.promiseDates[i] = new PromiseDateEntityExtensionClass(odataObject.promiseDates[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.promiseDates[i] = undefined;
                }
            }
        }
    }
}

/**
 * GetPromiseStockAvailQtyResponse entity class.
 */
export class GetPromiseStockAvailQtyResponseExtensionClass implements IGetPromiseStockAvailQtyResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public QtyAvail: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.QtyAvail = odataObject.QtyAvail ? parseFloat(odataObject.QtyAvail) : 0;
    }
}

/**
 * CalculateStockAvailDateResponse entity class.
 */
export class CalculateStockAvailDateResponseExtensionClass implements ICalculateStockAvailDateResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DateAvail: Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Success: boolean;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DateAvail =
            odataObject.DateAvail instanceof Date
                ? odataObject.DateAvail
                : odataObject.DateAvail
                ? jsonLightReadStringPropertyValue(odataObject.DateAvail, 'Edm.DateTimeOffset', false)
                : undefined;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Success = odataObject.Success;
    }
}

/**
 * GetUserLevelByExternalIdResponse entity class.
 */
export class GetUserLevelByExternalIdResponseExtensionClass implements IGetUserLevelByExternalIdResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public userLevel: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public userId: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.userLevel = odataObject.userLevel;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.userId = odataObject.userId;
    }
}
